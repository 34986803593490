<!--
 * @Description: 描述
 * @Author: D
 * @Date: 2024-03-07 10:28
 * @LastEditTime: 2024-03-18 10:49
 * @LastEditors: D
 * @FilePath: \aiqinghua\src\components\Answer.vue
-->
<template>
  <div class="askingBottom" >
    <!--回答 -->
    <img src="../assets/image/a.png" alt="">
    <div class="setAsking"></div>
  </div>
</template>

<script>
export default {
  name: 'VueAiAnswer',

  data() {
    return {
      
    };
  }
};
</script>
<style lang="less" scoped>
    .askingBottom{
        width: 500px;
        height: 120px;
        display: flex;
        justify-content: space-around;
        img{
            width: 50px;
            height: 50px;
        }
        .setAsking{
            width: 400px;
            margin: 0 10px;
        }
    }
    
</style>