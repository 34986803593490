<template>
  <div class="askingTop">
    <!--提问-->
    <img src="../assets/image/my.png" alt="">
    <div class="getAsking">{{ ptitle }}</div>
  </div>
</template>

<script>
import bus from '../js/eventBus'
export default {
  name: 'VueAiAskingQuestions',
  props: {
    ptitle: {
      type: String
    }
  },
  data() {
    return {

    };
  },
  mounted() {
    // bus.$on('Value',data =>{
    //     this.question=data
    // })
  }
}
</script>

<style lang="less" scoped>
.askingTop {
  width: 500px;
  height: 120px;
  display: flex;
  justify-content: space-around;

  img {
    width: 50px;
    height: 50px;
  }

  .getAsking {
    width: 400px;
    margin: 0 10px;
  }
}
</style>