import  request  from "@/utils/request"

// 文字
// export function getAiMassage (data) {
//     return request({
//         url: 'https://open.bigmodel.cn/api/paas/v4/chat/completions',
//         method: 'post',
//         data
//     })
// }

// 图片
export function getAiMassage (data) {
    return request({
        url: 'https://open.bigmodel.cn/api/paas/v4/chat/completions',
        method: 'post',
        data
    })
}

// 图片地址
export function fileUpload (data) {
    return request({
        url: '/file/fileUpload',
        method: 'POST',
        data
    })
} 