<!--
 * @Description: 描述
 * @Author: D
 * @Date: 2024-03-07 10:28
 * @LastEditTime: 2024-03-20 10:53
 * @LastEditors: D
 * @FilePath: \aiqinghua\src\components\QuestionInputBox.vue
-->

<template>
    <div class="footer">
        <!--问题输入框 -->
        <el-upload class="upload-demo" action="#" :limit="99" :before-upload="handleUpload"
            accept=".png,.jpg,.jpeg,.gif,.webp" :http-request="beforeAvatarUploadThree" :show-file-list="false">
            <el-button class="el-icon-upload lang-ty"></el-button>
        </el-upload>
        <el-input v-model="question" placeholder="请输入您的问题" @keyup.enter.native="handleClickQuestion">
        </el-input>
        <img src="../assets/image/send.png" alt="" @click="handleClickQuestion">
    </div>
</template>

<script>
import { fileUpload } from '@/api/index'
export default {
    name: 'VueAiQuestionInputBox',
    data() {
        return {
            question: "",
            purpose: "fine-tune"
        };
    },
    methods: {
        handleClickQuestion() {
            this.$emit("handleClickChange", this.question)
            this.question = ""
        },
        // 上传图片判断
        handleUpload(file) {
            let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
            const extension =
                testmsg.toLowerCase() === "png" ||
                testmsg.toLowerCase() === "jpg" ||
                testmsg.toLowerCase() === "jpeg" ||
                testmsg.toLowerCase() === "gif" ||
                testmsg.toLowerCase() === "webp";

            const isLimit10M = file.size / 1024 / 1024 < 10;
            let bool = false;
            if (extension && isLimit10M) {
                bool = true;
            } else {
                bool = false;
            }
            if (!extension) {
                this.$message.error("请上传图片格式文件！");
                return bool;
            }
            if (!isLimit10M) {
                this.$message.error("文件太大,单个文件不能超过10M! ");
                return bool;
            }
            return bool;
        },
        async beforeAvatarUploadThree(file) {
            const formData = new FormData();
            formData.append("multipartFile", file.file);
            let fileimg = await fileUpload(formData);
            if (fileimg.code == 200) {
                this.$emit("beforAvatarUpload", fileimg.entity)
            } else {
                this.$message.error('图片上传失败')
            }
        },
    }
};
</script>

<style lang="less" scoped>
.footer {
    // width: calc(100vw - 780px);
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 20px;

    .el-input /deep/ .el-input__inner {
        width: 1000px;
        height: 80px;
        border: 1px solid #d3d7dd;
        background-color: #fff;
        font-size: 22px;
        border-radius: 16px;
        padding-left: 16px;
        margin-top: -10px;
    }

    .el-button--primary {
        border: none;
    }


    .lang-ty {
        margin-top: 2px;
        font-size: 30px;
        border: none;
        background: none;
    }


    img {
        width: 65px;
        height: 65px;
    }
}
</style>