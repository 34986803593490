/* 
* @Description: 描述
* @Author: D
* @Date: 2024-03-07 10:28
* @LastEditTime: 2024-03-07 12:04
* @LastEditors: D
* @FilePath: \aiqinghua\src\main.js
*/
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import "reset-css"
Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
