<template>
    <router-view />



</template>

<script>

export default {
  name: 'VueAiApp',

  data() {
    return {
      question:""
    };
  },

  methods: {
    handleClickQuestion(question) {
      this.question=question;
    }
  }
};
</script>
<style lang="less">


</style>
