<!--
 * @Description: 描述
 * @Author: D
 * @Date: 2024-03-07 10:28
 * @LastEditTime: 2024-03-20 10:51
 * @LastEditors: D
 * @FilePath: \aiqinghua\src\views\HomeView.vue
-->
<template>
    <div class="container">
        <div class="avatar">
            <div class="title">AI教师</div>
            <img class="img" src="../assets/image/ai1.png" alt="">
        </div>
        <div class="right-body" ref="scrooll">
            <div>
                <div v-for="(item, index) in list" :key="index">
                    <div class="message" v-loading="item.loading">
                        <img v-if="item.user == 'user' || item.user == 'imgfile'" class="user-avatar"
                            src="../assets/image/my.png" alt="">
                        <img v-else class="user-avatar" src="../assets/image/a.png" alt="">
                        <div v-if="item.user == 'user'" class="getAsking" v-html="item.content"></div>
                        <img :src="item.urlfile" alt="" v-else-if="item.user == 'imgfile'"
                            style="width: 100px;height: 100px;margin-left: 20px;">
                        <pre v-else class="getAsking">{{ item.content }}</pre>
                    </div>
                </div>
            </div>
            <QuestionInputBox @handleClickChange="handleClickQuestion" @beforAvatarUpload="beforeAvatarUploadThree">
            </QuestionInputBox>
        </div>
    </div>


</template>

<script>
import AskingQuestions from "../components/AskingQuestions.vue";
import Answer from "../components/Answer.vue";
import QuestionInputBox from '../components/QuestionInputBox.vue'
// @ is an alias to /src
import { getAiMassage } from "@/api/index.js"


export default {
    name: 'HomeView',
    components: {
        AskingQuestions,
        Answer,
        QuestionInputBox
    },
    data() {
        return {
            question: "",
            urlfile: '',
            list: [{ user: 'admin', content: '请输入你的问题...', urlfile: '' }],
        };
    },

    methods: {
        async getAiMassage() {
            let index = this.list.length
            this.list.push({
                user: 'admin', content: '', loading: true
            })
            setTimeout(() => {
                const scrollContainer = this.$refs.scrooll;
                scrollContainer.scrollTop = scrollContainer.scrollHeight - scrollContainer.clientHeight;
            }, 200);

            const textdata = {
                model: "glm-4v",
                messages: [
                    { "role": "user", "content": this.question },
                ]
            }
            const imgdata = {
                model: "glm-4v",
                messages: [
                    {
                        "role": "user",
                        "content": [
                            {
                                "type": "text",
                                "text":'描述一下图中内容'
                            }, {
                                "type": "image_url",
                                "image_url": {
                                    "url": "" + this.urlfile
                                }
                            }
                        ]
                    }
                ]
            }
            let data = {};
            if (this.urlfile) {
                data = imgdata
            } else {
                data = textdata
            };
            const res = await getAiMassage(data)
            if (res) {
                let arr = {
                    user: res.choices[0].message.role, content: res.choices[0].message.content, loading: false
                }
                this.list[index] = arr
                if (this.list[0].content == "请输入你的问题...") {
                    this.list.shift();
                }
                this.$set(this.list, this.list)
                setTimeout(() => {
                    const scrollContainer = this.$refs.scrooll;
                    scrollContainer.scrollTop = scrollContainer.scrollHeight - scrollContainer.clientHeight;
                }, 200);

            }

        },
        handleClickQuestion(question) {
            this.question = question;
            this.list.push({ user: 'user', content: question, loading: false })
            const scrollContainer = this.$refs.scrooll;
            scrollContainer.scrollTop = scrollContainer.scrollHeight - scrollContainer.clientHeight;

            this.getAiMassage()
        },
        beforeAvatarUploadThree(file) {
            this.urlfile = file
            this.list.push({ user: 'imgfile', content: this.question, urlfile: '' + file, loading: false })
            const scrollContainer = this.$refs.scrooll;
            scrollContainer.scrollTop = scrollContainer.scrollHeight - scrollContainer.clientHeight;

            this.getAiMassage()
        },
        enterset() {
            this.handleClickQuestion(question)
        }
    }
}
</script>

<style lang="less" scoped>
.container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 auto;

    .avatar {
        width: 400px;
        min-width: 400px;
        height: 100vh;
        overflow: hidden;

        .title {
            text-align: center;
            font-size: 40px;
            padding: 32px 0;
        }

        .img {
            margin-top: 60px;
            width: 400px;
            min-width: 400px;
        }

    }

    .right-body {
        padding: 24px;
        padding-bottom: 80px;
        overflow: hidden;
        overflow-y: auto;
        max-height: calc(100vh - 108px);

        .message {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 16px;

            .user-avatar {
                width: 50px;
                height: 50px;
                // margin-right: 16px;
            }

            .getAsking {
                padding: 0 16px;
                line-height: 32px;
                // width: 500px;
                white-space: pre-wrap;       
                white-space: -moz-pre-wrap;  
                white-space: -pre-wrap;      
                white-space: -o-pre-wrap;  
                // border: 1px solid #ccc;
            }
        }
    }

}
</style>
