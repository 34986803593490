/* 
* @Description: 描述
* @Author: D
* @Date: 2024-03-07 10:28
* @LastEditTime: 2024-05-28 15:38
* @LastEditors: D
* @FilePath: \aiqinghua\src\utils\request.js
*/
import axios from 'axios'
// 请求拦截器的封装
// instance 实例 对象
const instance = axios.create({
    baseURL:"",
    timeout:5000000
})


//请求拦截器
instance.interceptors.request.use(config => {
    config.headers['Authorization'] = "eyJhbGciOiJIUzI1NiIsInNpZ25fdHlwZSI6IlNJR04ifQ.eyJhcGlfa2V5IjoiOGE5YmQyMjc3YzRiNDQwOGYyMDc3MzUwNWUxYWNjYjAiLCJleHAiOjE3NDg0MTc4MTAwMDAsInRpbWVzdGFtcCI6MTcxNjg4MTgxMDAwMH0.bqfWIjb0e_4T-AhPqC_ijE2HPmvwVQBeHRC2UmDw55Q"
    // 记录本此请求的相关信息
    return config
},err =>{
    return Promise.reject(err)
})


//响应拦截器
instance.interceptors.response.use(res => {
    
    return res.data
},err =>{
    return Promise.reject(err)
})


function request(p) {
    return instance({
        method: p.method,
        data: p.data,
        url: p.url
    })
}
export default request